import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout, SEO, Map} from '../components'

const Contact = ({ data }) => (
  <Layout>
    <SEO title="Contact" />

    <section className="container px-5 pb-0 text-center">
      <h1 className="mt-md-5 mb-4">Small team. Big hearts.</h1>
      <p className="lead mb-5">
        Our focus is always on finding the best people to work with.
      </p>
    </section>
    <section className="container px-md-5 pb-5">
      <Img
        className="img-fluid"
        fluid={data.team.childImageSharp.fluid}
        alt="Time Lapse team"
      />
    </section>

    <Map />

    <section role="main" className="container p-5 text-center">
      <h1 className="mb-4">We want to hear from you!</h1>
      <p className="lead mb-5">
        Let us know how we can best help you and we'll do our very best.
      </p>
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-10 text-left">
          <form action="https://formspree.io/xwkrpypy" method="POST">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="contactName">
                    Name
                  </label>
                  <input type="text" className="form-control form-control-lg" name="name" id="contactName" placeholder="Full Name" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="contactEmail">
                    Email
                  </label>
                  <input type="email" className="form-control form-control-lg" name="_replyto" id="contactEmail" placeholder="hello@domain.com" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="projectType">
                    Project Type
                  </label>
                  <input type="text" className="form-control form-control-lg" name="projecttype" id="projectType" placeholder="High-rise building/Infrastructure/Others" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="projectDuration">
                    Project Duration
                  </label>
                  <input type="text" className="form-control form-control-lg" name="projectduration" id="projectDuration" placeholder="12months/24months/Others" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-5">
                  <label htmlFor="projectLocation">
                    Project Location
                  </label>
                  <input type="text" className="form-control form-control-lg" name="projectlocation" id="projectLocation" placeholder="Kuala Lumpur/Selangor/Others" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-7 mb-md-9">
                  <label htmlFor="contactMessage">
                    What can we help you with?
                  </label>
                  <textarea className="form-control form-control-lg" name="message" id="contactMessage" rows="5" placeholder="Tell us what we can help you with!"></textarea>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <input type="hidden" name="_subject" value="Enquiry from Timelapse.my" />
                <button type="submit" className="btn btn-lg btn-primary lift">
                  Send message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
)

export default Contact

export const query = graphql`
  query {
    team: file(relativePath: {eq: "about-team.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`